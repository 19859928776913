import { useState, useMemo } from 'react';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import HBMediaElement from '../../../honeybook-ui/HBMediaElement/HBMediaElement';

import './feature-lead-animation.scss';

interface Props {
    customClass: string;
}

const FeatureLeadAnimation = ({ customClass }: Props) => {
    const [progress, setProgress] = useState(0);

    const animationData = useMemo(
        () => ({
            scrollLength: 30,
            startPercentage: {
                left: 20,
                right: -20
            }
        }),
        []
    );

    const handleScroll = progress => {
        const updatedProgress = progress * animationData.scrollLength;
        setProgress(updatedProgress);
    };

    return (
        <div className={`feature-lead-animation ${customClass}`}>
            <ParallaxProvider scrollAxis="vertical">
                <Parallax
                    onProgressChange={handleScroll}
                    style={{ position: 'absolute', inset: '0px' }}
                />
                <div className="feature-lead-animation__content">
                    <div
                        className="feature-lead-animation__image-wrapper"
                        style={{
                            translate: `0px ${
                                animationData.startPercentage.left - progress
                            }%`
                        }}
                    >
                        <HBMediaElement
                            customClass="feature-lead-animation__image"
                            name="marketing_site/crm-page/feature-info-block/lead/left_v2.png"
                            lazyLoad={false}
                        />
                    </div>

                    <div
                        className="feature-lead-animation__image-wrapper"
                        style={{
                            translate: `0px ${
                                animationData.startPercentage.right + progress
                            }%`
                        }}
                    >
                        <HBMediaElement
                            customClass="feature-lead-animation__image"
                            name="marketing_site/crm-page/feature-info-block/lead/right_v2.png"
                            lazyLoad={false}
                        />
                    </div>
                </div>
            </ParallaxProvider>
        </div>
    );
};

export default FeatureLeadAnimation;
