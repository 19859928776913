import { useCallback } from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Layout from '../components/Layout/Layout';
import HBButton from '@honeybook-ui/HBButton/HBButton';

import WhiteHero from '../components/Hero/WhiteHero/WhiteHero';
import RegistrationForm from '../components/RegistrationForm/RegistrationForm';
import ImageTextElementBlock from '../components/FeatureBlocks/ImageTextElementBlock/ImageTextElementBlock';
import PictureAndTextBlock from '../components/FeatureBlocks/PictureAndTextBlock/PictureAndTextBlock';
import ImageTextElementsBlock from '../components/FeatureBlocks/ImageTextElementsBlock/ImageTextElementsBlock';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';
import CTABlock from '../components/FeatureBlocks/CTABlock/CTABlock';
import WereFirstClassBlock from '../components/FeatureBlocks/WereFirstClassBlock/WereFirstClassBlock';

import AnalyticsService, {
    AnalyticsEvents
} from '../Services/AnalyticsService';

import { FeatureBlockText } from '../components/FeatureBlockElements';

import '../styles/referrals.scss';

const imageTextElements = [
    {
        title: 'ReferralsPage.imageTextElements.inviteNetwork.title',
        text: 'ReferralsPage.imageTextElements.inviteNetwork.body',
        imagePath: 'marketing_site/referrals_page/gift-email',
        altText: 'ReferralsPage.imageTextElements.inviteNetwork.altText',
        mediaFlags: { forceImgTag: true, width: 170 }
    },
    {
        title: 'ReferralsPage.imageTextElements.trackInvites.title',
        text: 'ReferralsPage.imageTextElements.trackInvites.body',
        imagePath: 'marketing_site/referrals_page/track-invitees',
        altText: 'ReferralsPage.imageTextElements.trackInvites.altText',
        mediaFlags: { forceImgTag: true, width: 170 }
    },
    {
        title: 'ReferralsPage.imageTextElements.earnBig.title',
        text: 'ReferralsPage.imageTextElements.earnBig.body',
        imagePath: 'marketing_site/referrals_page/money',
        altText: 'ReferralsPage.imageTextElements.earnBig.altText',
        mediaFlags: { forceImgTag: true, width: 170 }
    }
];

const faqs = [
    {
        id: 'referralsPassiveIncome'
    },
    {
        id: 'referralsPartOfProgram',
        answer: '#TEXT# <a href="https://intercom.help/honeybook-5891e2a409ff/en/articles/2209188-the-difference-between-basic-referral-and-premium-referral">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'referralsSharingLink',
        answer: '#TEXT# <a href="https://intercom.help/honeybook-5891e2a409ff/en/articles/2209197-referral-program-best-practices">#LINK#_TEXT#</a> #TEXT#'
    },
    {
        id: 'referralsTermsAndConditions',
        answer: '#TEXT# <a href="/lp/referrals-tos">#TEXT#</a>#TEXT# <a href="mailto:concierge@honeybook.com">#TEXT#</a>#TEXT#'
    }
];

const ogTags = {
    url: 'https://www.honeybook.com/referrals',
    title: 'Referral Program: Refer Friends, Earn Cash',
    description:
        'Our Referral Program is a way of rewarding you when you help bring a new member into the HoneyBook platform. Share your unique referral link to get started.'
};

const handleAnalytics = context => {
    AnalyticsService.trackClick(AnalyticsEvents.referrals_module_link, context);
};
const inviteReferralsLink = '/app/invite/referrals';

function ReferralsTemplate() {
    const { t } = useTranslation();

    const onHeroClick = useCallback(() => {
        handleAnalytics({ source: 'hero' });
    }, []);

    const onFirstLinkClick = useCallback(() => {
        handleAnalytics({ source: 'empower network' });
    }, []);

    const onSecondLinkClick = useCallback(() => {
        handleAnalytics({ source: 'unlock premium' });
    }, []);

    const onThirdLinkClick = useCallback(() => {
        () => handleAnalytics({ source: 'give discount' });
    }, []);

    const onFourthLinkClick = useCallback(() => {
        handleAnalytics({ source: 'track invites' });
    }, []);

    return (
        <div className="referrals-page">
            <Layout ogTags={ogTags}>
                <WhiteHero
                    image="marketing_site/referrals_page/gift"
                    mainTitle={t('ReferralsPage.hero.title')}
                    description={t('ReferralsPage.hero.subtitle')}
                    showRegistration={false}
                >
                    <a
                        className="referrals-page__hero-link"
                        href={inviteReferralsLink}
                    >
                        <HBButton
                            customClass="referrals-page__hero-button-link"
                            onClick={onHeroClick}
                            text={t('ReferralsPage.hero.cta')}
                        />
                    </a>

                    <span className="referrals-page__hero-subcta">
                        {t('ReferralsPage.hero.subCta')} &nbsp;
                        <RegistrationForm
                            customClass="referrals-page__hero-button-link"
                            buttonText={t('ReferralsPage.hero.subCtaLink')}
                            source="hero"
                        />
                    </span>
                </WhiteHero>

                <ImageTextElementBlock
                    customClass="referrals-page__built-with-you-in-mind"
                    imageUrl="marketing_site/referrals_page/trophy"
                    lightBackground
                    mediaFlags={{
                        alt: t(
                            'ReferralsPage.illustrationSecondaryBlockA.title'
                        )
                    }}
                >
                    <FeatureBlockText
                        primary={t(
                            'ReferralsPage.illustrationSecondaryBlockA.title'
                        )}
                        bodyText={t(
                            'ReferralsPage.illustrationSecondaryBlockA.bodyText'
                        )}
                    />
                </ImageTextElementBlock>

                <PictureAndTextBlock
                    customClass="referrals-page__picture-text-block referrals-page__empower-your-network"
                    primary={t('ReferralsPage.picureAndTextBlockA.title')}
                    secondary={t('ReferralsPage.picureAndTextBlockA.body')}
                    image="marketing_site/referrals_page/module2.gif"
                >
                    <div className="referrals-page__block-link-container">
                        <a
                            className="referrals-page__block-link"
                            href={inviteReferralsLink}
                        >
                            <HBButton
                                customClass="referrals-page__block-button-link"
                                onClick={onFirstLinkClick}
                                text={t(
                                    'ReferralsPage.picureAndTextBlockA.cta'
                                )}
                            />
                        </a>
                    </div>
                </PictureAndTextBlock>

                <PictureAndTextBlock
                    customClass="referrals-page__picture-text-block referrals-page__unlock-premium-referrals"
                    primary={t('ReferralsPage.picureAndTextBlockB.title')}
                    secondary={t('ReferralsPage.picureAndTextBlockB.body')}
                    image="marketing_site/referrals_page/module3_2x.gif"
                    imageWidth={'auto'}
                >
                    <div className="referrals-page__block-link-container">
                        <a
                            className="referrals-page__block-link"
                            href={inviteReferralsLink}
                        >
                            <HBButton
                                customClass="referrals-page__block-button-link"
                                onClick={onSecondLinkClick}
                                text={t(
                                    'ReferralsPage.picureAndTextBlockB.cta'
                                )}
                            />
                        </a>
                    </div>
                </PictureAndTextBlock>

                <CTABlock
                    lightBackground
                    customClass="referrals-page__well-spent"
                    imageUrl="marketing_site/referrals_page/module4.svg"
                    primary={t(
                        'ReferralsPage.illustrationSecondaryBlockB.body1'
                    )}
                    secondary={t(
                        'ReferralsPage.illustrationSecondaryBlockB.body2'
                    )}
                    ctaSource="money-well-spent"
                />

                <PictureAndTextBlock
                    customClass="referrals-page__picture-text-block referrals-page__give-an-amazing-discount"
                    primary={t('ReferralsPage.picureAndTextBlockC.title')}
                    secondary={t('ReferralsPage.picureAndTextBlockC.body')}
                    image="marketing_site/referrals_page/module5_2x.png"
                    imageWidth={400}
                >
                    <div className="referrals-page__block-link-container">
                        <a
                            className="referrals-page__block-link"
                            href={inviteReferralsLink}
                        >
                            <HBButton
                                customClass="referrals-page__block-button-link"
                                onClick={onThirdLinkClick}
                                text={t(
                                    'ReferralsPage.picureAndTextBlockC.cta'
                                )}
                            />
                        </a>
                    </div>
                </PictureAndTextBlock>

                <PictureAndTextBlock
                    customClass="referrals-page__picture-text-block referrals-page__track-your-invites"
                    primary={t('ReferralsPage.picureAndTextBlockD.title')}
                    secondary={t('ReferralsPage.picureAndTextBlockD.body')}
                    image="marketing_site/referrals_page/static-module6-02.png"
                    imageWidth="auto"
                >
                    <div className="referrals-page__block-link-container">
                        <a
                            className="referrals-page__block-link"
                            href={inviteReferralsLink}
                        >
                            <HBButton
                                customClass="referrals-page__block-button-link"
                                onClick={onFourthLinkClick}
                                text={t(
                                    'ReferralsPage.picureAndTextBlockD.cta'
                                )}
                            />
                        </a>
                    </div>
                </PictureAndTextBlock>

                <ImageTextElementsBlock
                    lightBackground
                    title={t('ReferralsPage.imageTextElements.title')}
                    customClass="referrals-page__how-it-works"
                    imageTextElements={imageTextElements}
                />

                <CTABlock
                    customClass="referrals-page__not-member-yet"
                    primary={t('ReferralsPage.ctaBlock.primary')}
                    secondary={t('ReferralsPage.ctaBlock.secondary')}
                    ctaSource="not member yet"
                />

                <FAQBlock title={t('ReferralsPage.faqTitle')} faqs={faqs} />

                <WereFirstClassBlock />
            </Layout>
        </div>
    );
}

export default ReferralsTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "referrals", "faqs"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
