import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import ImageTextElementsBlock from '../ImageTextElementsBlock/ImageTextElementsBlock';

import TableCrossIcon from '../../../svg/cross.inline.svg';
import TableCheckIcon from '../../../svg/check.inline.svg';
import TableCrossMobileIcon from '../../../svg/cross-mobile.inline.svg';
import TableCheckMobileIcon from '../../../svg/check-mobile.inline.svg';

import './best-practices-block.scss';

const ShowcaseServicesBlock = () => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();

    const imageTextElements = [
        {
            title: t(`BestPracticesBlock.buckets.one.title`),
            text: t(`BestPracticesBlock.buckets.one.description`),
            imagePath: 'marketing_site/smart-files/best-practices-01.png',
            mediaFlags: {
                width: 127,
                height: 127
            }
        },
        {
            title: t(`BestPracticesBlock.buckets.two.title`),
            text: t(`BestPracticesBlock.buckets.two.description`),
            imagePath: 'marketing_site/smart-files/best-practices-02.png',
            mediaFlags: {
                width: 127,
                height: 127
            }
        },
        {
            title: t(`BestPracticesBlock.buckets.three.title`),
            text: t(`BestPracticesBlock.buckets.three.description`),
            imagePath: 'marketing_site/smart-files/best-practices-03.png',
            mediaFlags: {
                width: 127,
                height: 127
            }
        }
    ];

    const tableData = ['one', 'two', 'three', 'four', 'five', 'six', 'seven'];

    return (
        <FeatureBlockSection customClass="best-practices-block">
            <FeatureBlockContent>
                <ImageTextElementsBlock
                    title={t(`BestPracticesBlock.title`)}
                    imageTextElements={imageTextElements}
                />
            </FeatureBlockContent>
            <FeatureBlockContent customClass="best-practices-block__table">
                <div className="best-practices-block__row best-practices-block__row--header">
                    <div className="best-practices-block__cell best-practices-block__cell--feature"></div>
                    <div className="best-practices-block__cell best-practices-block__cell--old best-practices-block__cell--header">
                        {t(`BestPracticesBlock.table.headerOld`)}
                    </div>
                    <div className="best-practices-block__cell best-practices-block__cell--new best-practices-block__cell--header">
                        <span className="sparkle-wrapper">
                            {t(`BestPracticesBlock.table.headerNew`)}
                        </span>
                    </div>
                </div>
                {tableData.map(id => {
                    return (
                        <div className="best-practices-block__row" key={id}>
                            <div className="best-practices-block__cell best-practices-block__cell--feature">
                                {t(`BestPracticesBlock.table.features.${id}`)}
                            </div>
                            <div className="best-practices-block__cell best-practices-block__cell--old">
                                {breakpoints.mediumUp ? (
                                    <TableCrossIcon />
                                ) : (
                                    <TableCrossMobileIcon />
                                )}
                            </div>
                            <div className="best-practices-block__cell best-practices-block__cell--new">
                                {breakpoints.mediumUp ? (
                                    <TableCheckIcon />
                                ) : (
                                    <TableCheckMobileIcon />
                                )}
                            </div>
                        </div>
                    );
                })}
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default ShowcaseServicesBlock;
