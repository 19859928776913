import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import FeatureBlockText from '../../FeatureBlockElements/FeatureBlockText/FeatureBlockText';
import HBVideo from '@honeybook-ui/HBVideo/HBVideo';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import VideoModal from '../../Modals/VideoModal/VideoModal';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';

import './interactive-files-block.scss';

const InteractiveFilesBlock = () => {
    const { t } = useTranslation();

    const [videoModalOpen, setVideoModalOpen] = useState(false);

    const toggleVideoModal = useCallback(isOpenModal => {
        AnalyticsService.trackClick(
            AnalyticsEvents.interactive_files_video_modal,
            {
                source: isOpenModal ? 'open video modal' : 'close video modal'
            }
        );
        setVideoModalOpen(videoModalOpen => !videoModalOpen);
    }, []);

    return (
        <FeatureBlockSection customClass="interactive-files-block">
            <FeatureBlockContent>
                <FeatureBlockText
                    primary={t('InteractiveFilesBlock.title')}
                    secondary={t('InteractiveFilesBlock.subtitle')}
                    fullWidth
                />
                <HBVideo
                    customClass="interactive-files-block__video"
                    autoplay
                    loop
                    muted
                    videoId="766175181"
                    title={t('InteractiveFilesBlock.title')}
                />
                <button
                    className="hbui-button hbui-button--medium hbui-button--primary interactive-files-block__cta"
                    onClick={() => toggleVideoModal(true)}
                >
                    <HBMediaElement
                        name="marketing_site/smart-files/second-module-play-24.svg"
                        forceImgTag
                    />{' '}
                    {t(`InteractiveFilesBlock.ctaText`)}
                </button>
                <VideoModal
                    video={{
                        id: 'hero',
                        videoURL:
                            'https://www.youtube.com/embed/plzmUXjKz_Y?autoplay=1&controls=0&rel=0'
                    }}
                    customClass="homepage-product-hero-new__video-modal"
                    isOpen={videoModalOpen}
                    onClose={() => toggleVideoModal(false)}
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default InteractiveFilesBlock;
