import { graphql } from 'gatsby';
import { useTranslation, Trans } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import HBButton from '@honeybook-ui/HBButton/HBButton';
import WhiteHero from '../components/Hero/WhiteHero/WhiteHero';
import RegistrationForm from '../components/RegistrationForm/RegistrationForm';
import TemplateGallery from '../components/FeatureBlocks/TemplateGallery/TemplateGallery';
import UtilsService from '../Services/UtilsService';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';
import FreeToTryBlockRebrand from '../components/FeatureBlocks/FreeToTryBlockRebrand/FreeToTryBlockRebrand';
import ImageTextElementLinkGrid from '../components/FeatureBlocks/ImageTextElementLinkGridBlock/ImageTextElementLinkGridBlock';
import PictureAndTextWrapBlock from '../components/FeatureBlocks/PictureAndTextWrapBlock/PictureAndTextWrapBlock';
import AnalyticsService, {
    AnalyticsEvents
} from '../Services/AnalyticsService';
import TemplateStore from '../stores/TemplatesStore';
import { TRANSLATION_COMPONENTS } from '../constants/constants';

import '../styles/free-contract-templates.scss';

const otherFeatures = [
    {
        id: 'proposals',
        image: 'marketing_site/invoice-generator-lp/tutors/Module%209/Proposals.svg',
        linkUrl: '/proposal-software'
    },
    {
        id: 'contracts',
        image: 'marketing_site/invoice-generator-lp/tutors/Module%209/contracts.svg',
        linkUrl: '/online-contract'
    },
    {
        id: 'allInOne',
        image: 'marketing_site/invoice-generator-lp/tutors/Module%209/all_in_one.svg',
        linkUrl: '/all-in-one-business-platform'
    },
    {
        id: 'mobileApp',
        image: 'marketing_site/invoice-generator-lp/tutors/Module%209/mobile.svg',
        linkUrl: 'https://honeybook.onelink.me/850908038/qpe0ybvh'
    },
    {
        id: 'scheduling',
        image: 'marketing_site/invoice-generator-lp/tutors/Module%209/Scheduling.svg',
        linkUrl: '/meeting-scheduler'
    },
    {
        id: 'onlinePayments',
        image: 'marketing_site/invoice-generator-lp/tutors/Module%209/payments.svg',
        linkUrl: '/online-payment-software'
    }
];

const faqs = [
    {
        id: 'contractHubCreate'
    },
    {
        id: 'contractHubCreateSimple'
    },
    {
        id: 'contractHubBasic'
    },
    {
        id: 'contractHubCreateTemplate'
    },
    {
        id: 'contractHubIncluded'
    },
    {
        id: 'contractHubContractTypes'
    },
    {
        id: 'contractHubSoftware'
    },
    {
        id: 'contractHubNeed'
    },
    {
        id: 'contractHubBestSoftware'
    },
    {
        id: 'contractsLegal'
    }
];

const { templates } = TemplateStore;

const categories: string[] = [];

templates.forEach(template => {
    if (!categories.includes(template.vertical))
        categories.push(template.vertical);
});

const ogTags = {
    url: 'https://www.honeybook.com/free-contract-templates',
    title: 'Best Free Contract Templates for Small Businesses',
    description:
        'Discover the best free contract templates for your industry. Create templates in seconds. Send and sign contracts online to protect your business today.'
};

const handleHeroButtonClick = () => {
    AnalyticsService.trackClick(AnalyticsEvents.explore_our_free_invoices);
    UtilsService.scrollToElementId('contract-gallery-wrapper');
};

function FreeContractTemplatesTemplate(): JSX.Element {
    const { t } = useTranslation();

    return (
        <section className={'contracts-hub'}>
            <Layout ogTags={ogTags}>
                <WhiteHero
                    image="marketing_site/contracts-hub/hero-image.png"
                    width={550}
                    preTitle={t('ContractsHub.hero.pretitle')}
                    mainTitle={t('ContractsHub.hero.title')}
                    imageAltTag={t('ContractsHub.hero.title')}
                    description={t('ContractsHub.hero.description')}
                    showRegistration={false}
                >
                    <HBButton
                        onClick={handleHeroButtonClick}
                        text={t('ContractsHub.hero.cta')}
                    />
                </WhiteHero>

                <div id="contract-gallery-wrapper">
                    <TemplateGallery templateTypeId="contract" />
                </div>

                <PictureAndTextWrapBlock
                    customClass="contracts-hub__picture-text-block"
                    primary={t('ContractsHub.pictureTextWrapBlock.title')}
                    image="marketing_site/contracts-hub/module-2-image_2x.png"
                    imageWidth={800}
                    imageAltTag={t('ContractsHub.pictureTextWrapBlock.title')}
                >
                    <p className="contracts-hub__picture-text-block-text">
                        {t(`ContractsHub.pictureTextWrapBlock.body.p1`)}
                    </p>
                    <p className="contracts-hub__picture-text-block-text">
                        <Trans
                            i18nKey="ContractsHub.pictureTextWrapBlock.body.p2"
                            components={TRANSLATION_COMPONENTS}
                        />
                    </p>
                    <RegistrationForm
                        source="best invoicing software"
                        customClass="contracts-hub__picture-text-block-button"
                        buttonText={t('ContractsHub.pictureTextWrapBlock.cta')}
                        showNoCcRequired
                    />
                </PictureAndTextWrapBlock>

                <ImageTextElementLinkGrid
                    lightBackground
                    transKey="ContractsHub.otherFeatures"
                    imageTextElements={otherFeatures}
                />

                <FAQBlock
                    title={t(`ContractsHub.generic.faqTitle`)}
                    faqs={faqs}
                />

                <FreeToTryBlockRebrand />
            </Layout>
        </section>
    );
}

export default FreeContractTemplatesTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: {
                    in: ["contractsHub", "templateGallery", "common", "faqs"]
                }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
