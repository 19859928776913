import { useTranslation, Trans } from 'react-i18next';
import { TRANSLATION_COMPONENTS } from '../../../constants/constants';
import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import FeatureBlockText from '../../FeatureBlockElements/FeatureBlockText/FeatureBlockText';
import FeatureBlockUserQuote from '../../FeatureBlockElements/FeatureBlockUserQuote/FeatureBlockUserQuote';
import FeatureBlockImage from '../../FeatureBlockElements/FeatureBlockImage/FeatureBlockImage';
import InvoiceReviewBlockStore from './InvoiceReviewBlockStore';
import ProposalsToast from '../../ProposalsToast/ProposalsToast';

import './invoice-review-block.scss';

const InvoiceReviewBlock = () => {
    const { t } = useTranslation();

    const { featureImage, quoteAvatar } = InvoiceReviewBlockStore;
    return (
        <FeatureBlockSection
            customClass="feature-block__invoice-review"
            lightBackground
        >
            <FeatureBlockContent>
                <FeatureBlockImage
                    customClass="feature-block__invoice-review-img"
                    featureImage={featureImage}
                    width={570}
                    crop="scale"
                />
                <ProposalsToast
                    iconClass="icon-hb-nx-eye-open"
                    content={t('featureBlock.invoiceReview.toastContent')}
                    timeAgo={t('featureBlock.invoiceReview.timeAgo')}
                    entranceDirection="left"
                />
            </FeatureBlockContent>
            <FeatureBlockContent position="right">
                <FeatureBlockText
                    primary={
                        <Trans
                            i18nKey="featureBlock.invoiceReview.title"
                            components={TRANSLATION_COMPONENTS}
                        />
                    }
                    secondary={t('featureBlock.invoiceReview.indicator.file')}
                    subheading={t('featureBlock.invoiceReview.subtitle')}
                />
                <FeatureBlockUserQuote
                    hideQuotes
                    name={t('featureBlock.invoiceReview.quote.userName')}
                    jobTitle={t('featureBlock.invoiceReview.quote.jobTitle')}
                    avatar={quoteAvatar}
                    avatarWidth={40}
                    avatarCrop="thumb"
                    quote={t('featureBlock.invoiceReview.quote.text')}
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default InvoiceReviewBlock;
