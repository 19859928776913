import React from 'react';
import PropTypes from 'prop-types';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';

import './template-block-cta-box.scss';

const TemplatesBlockCTABox = ({ image, title }) => (
    <>
        <div className="template-block-cta-box">
            <HBMediaElement name={image} forceImgTag width={150} />
            <h2 className="template-block-cta-box__title">{title}</h2>
            <RegistrationForm
                showNoCcRequired
                direction="column"
                source="template card cta box"
            />
        </div>
    </>
);

TemplatesBlockCTABox.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string
};

export default TemplatesBlockCTABox;
