import { memo } from 'react';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../FeatureBlockElements';
import StarReview from './StarReview';
import { CAPTERRA_REVIEWS, G2_REVIEWS } from '../../constants/constants';

import './star-reviews.scss';

const reviews = [
    {
        key: 'capterra',
        imagePath: 'marketing_site/testimonials/capterra-dark-x2.svg',
        mediaFlags: {
            width: 125
        },
        score: CAPTERRA_REVIEWS.rating.toString(),
        totalReviews: CAPTERRA_REVIEWS.totalReviews,
        url: CAPTERRA_REVIEWS.url,
        starsImageUrl: 'marketing_site/testimonials/starts-4-8.png'
    },
    {
        key: 'g2',
        imagePath: 'marketing_site/testimonials/g2-logo-x2.svg',
        mediaFlags: {
            width: 40
        },
        score: G2_REVIEWS.rating.toString(),
        totalReviews: G2_REVIEWS.totalReviews,
        url: G2_REVIEWS.url,
        starsImageUrl: 'marketing_site/testimonials/stars-4-5.png'
    }
];

interface Props {
    customClass?: string;
}

const StarReviews = ({ customClass }: Props): JSX.Element => {
    return (
        <FeatureBlockSection customClass={customClass}>
            <FeatureBlockContent>
                <div className="star-reviews">
                    {reviews.map(review => (
                        <StarReview key={review.key} review={review} />
                    ))}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default memo(StarReviews);
