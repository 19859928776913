import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import RegistrationForm from '../RegistrationForm/RegistrationForm';
import TemplateDownloadButton from '../TemplateDownloadButton/TemplateDownloadButton';

import AnalyticsService, {
    AnalyticsEvents
} from '../../Services/AnalyticsService';

import './template-element.scss';

const handleLoadToHBAnalytics = vertical => {
    AnalyticsService.trackClick(AnalyticsEvents.template_load_to_hb, {
        source: vertical
    });
};

const TemplateElement = ({
    t,
    vertical,
    template,
    hasGatedDownload,
    templateTypeId,
    ctaText = t(`TemplateElements.common.ctaLoadToHb`)
}) => {
    return (
        <div className="template-element">
            {template.imagePath && (
                <HBMediaElement
                    name={template.imagePath}
                    alt={`${t(
                        `TemplateElements.${vertical}.id.${template.id}.title`
                    )} ${t(`TemplateElements.common.altTag`)}`}
                    width={380}
                    crop="scale"
                />
            )}

            <div className="template-element__text-container">
                <h3 className="template-element__title">
                    {t(`TemplateElements.${vertical}.id.${template.id}.title`)}
                </h3>
                <p className="template-element__description">
                    {t(
                        `TemplateElements.${vertical}.id.${template.id}.description`
                    )}
                </p>

                <div className="template-element__button-container">
                    {template.downloadLink && (
                        <TemplateDownloadButton
                            invoiceName={t(
                                `TemplateElements.${vertical}.id.${template.id}.title`
                            )}
                            templateTypeId={templateTypeId}
                            downloadUrl={template.downloadLink}
                            hasGatedDownload={hasGatedDownload}
                        />
                    )}

                    <div
                        onClick={() => {
                            handleLoadToHBAnalytics(vertical);
                        }}
                    >
                        <RegistrationForm
                            customClass="template-element__load-template-btn"
                            direction="column"
                            templateId={template.templateId}
                            buttonText={ctaText}
                            source={`Contract template - ${template.id}`}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

TemplateElement.propTypes = {
    t: PropTypes.func,
    template: PropTypes.shape({}).isRequired,
    templateTypeId: PropTypes.string,
    vertical: PropTypes.string,
    ctaText: PropTypes.string,
    hasGatedDownload: PropTypes.bool
};

export default withTranslation()(TemplateElement);
