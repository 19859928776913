import { memo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { TRANSLATION_COMPONENTS } from '../../../constants/constants';

import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import FeatureBlockText from '../../FeatureBlockElements/FeatureBlockText/FeatureBlockText';
import FeatureBlockUserQuote from '../../FeatureBlockElements/FeatureBlockUserQuote/FeatureBlockUserQuote';
import FeatureBlockImage from '../../FeatureBlockElements/FeatureBlockImage/FeatureBlockImage';
import ProposalsToast from '../../ProposalsToast/ProposalsToast';

import './payment-booked-block.scss';

const featureImage = 'marketing_site/lp/proposal/payment.png';

const quoteAvatar = 'marketing_site/proposals_page/Taylor_of_Bixby_Pine.jpg';

function PaymentBookedBlock(): JSX.Element {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection
            customClass="feature-block__payment-booked"
            lightBackground
        >
            <FeatureBlockContent>
                <FeatureBlockImage
                    customClass="feature-block__payment-booked-img"
                    featureImage={featureImage}
                    width={570}
                />
                <ProposalsToast
                    position="bottom"
                    iconClass="icon-hb-nx-dollar-recurring"
                    content={t('featureBlock.paymentBooked.toastContent')}
                    timeAgo={t('featureBlock.paymentBooked.timeAgo')}
                    entranceDirection="left"
                />
            </FeatureBlockContent>
            <FeatureBlockContent position="right">
                <FeatureBlockText
                    primary={
                        <Trans
                            i18nKey="featureBlock.paymentBooked.title"
                            components={TRANSLATION_COMPONENTS}
                        />
                    }
                    secondary={t('featureBlock.paymentBooked.indicator.file')}
                    subheading={t('featureBlock.paymentBooked.subtitle')}
                />
                <FeatureBlockUserQuote
                    hideQuotes
                    name={t('featureBlock.paymentBooked.quote.userName')}
                    jobTitle={t('featureBlock.paymentBooked.quote.jobTitle')}
                    avatar={quoteAvatar}
                    avatarHeight={40}
                    avatarWidth={40}
                    avatarDpr={1}
                    avatarCrop="thumb"
                    avatarGravity="face"
                    quote={t('featureBlock.paymentBooked.quote.text')}
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
}

export default memo(PaymentBookedBlock);
