import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import TemplateElement from '../../TemplateElement/TemplateElement';
import TemplateBlockCTABox from './TemplateBlockCTABox';

import TemplateBlockStore from './TemplateBlockStore';
import './template-block.scss';

const TemplateBlock = ({
    t,
    vertical,
    templates,
    verticalLinks,
    templateTypeId
}) => {
    const { hasGatedDownload, ctaText } = TemplateBlockStore[templateTypeId];
    const { ctaBoxTitle, ctaBoxImage } =
        TemplateBlockStore[templateTypeId].templateBlockCta;

    return (
        <div className="template-block">
            <h2 className="template-block__title">
                {t(`TemplateGallery.${templateTypeId}.title`)}{' '}
                {t(`TemplateGallery.verticals.${vertical}`).toLowerCase()}
            </h2>
            <div className="template-block__vertical-links-container">
                <a
                    className="template-block__vertical-link"
                    href={verticalLinks.moreInvoices}
                >
                    {t(`TemplateGallery.${templateTypeId}.learnMore`)}{' '}
                    {t(`TemplateGallery.verticals.${vertical}`).toLowerCase()}
                </a>{' '}
                <a
                    className="template-block__vertical-link"
                    href={verticalLinks.softwareFor}
                >
                    {t(`TemplateGallery.${templateTypeId}.softwareFor`)}{' '}
                    {t(`TemplateGallery.verticals.${vertical}`).toLowerCase()}
                </a>
            </div>
            <div className="template-block__container">
                {templates.map(template => {
                    return (
                        <div key={template.id} className="template-block__item">
                            <TemplateElement
                                vertical={vertical}
                                template={template}
                                templateTypeId={templateTypeId}
                                hasGatedDownload={hasGatedDownload}
                                ctaText={t(ctaText)}
                            />
                        </div>
                    );
                })}
                {templates.length % 2 !== 0 && (
                    <div className="template-block__item">
                        <div className="template-element">
                            <TemplateBlockCTABox
                                title={t(ctaBoxTitle)}
                                image={t(ctaBoxImage)}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

TemplateBlock.propTypes = {
    t: PropTypes.func,
    vertical: PropTypes.string,
    templates: PropTypes.arrayOf(PropTypes.object),
    verticalLinks: PropTypes.shape({}),
    templateTypeId: PropTypes.string
};

export default withTranslation()(TemplateBlock);
