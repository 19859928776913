import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useInView } from 'react-intersection-observer';
import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import FeatureBlockText from '../../FeatureBlockElements/FeatureBlockText/FeatureBlockText';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import UtilsService from '../../../Services/UtilsService';

import './client-journey-block.scss';

const ClientJourneyBlock = () => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    const [clientJourneyRef, clientJourneyInView] = useInView();

    const [dotOneRef, dotOneInView] = useInView({
        triggerOnce: true,
        rootMargin: '200px 0px -200px 0px'
    });

    const [stepTwoRef, stepTwoInView] = useInView({
        triggerOnce: true,
        root: null,
        rootMargin: '300px 0px -200px 0px'
    });

    const [videoOne, videoOneInView] = useInView({
        triggerOnce: true,
        rootMargin: '-200px 0px -200px 0px',
        threshold: 0.5
    });

    const [videoTwo, videoTwoInView] = useInView({
        triggerOnce: true,
        rootMargin: '-200px 0px -200px 0px',
        threshold: 0.5
    });

    const [stepThreeRef, stepThreeInView] = useInView({
        triggerOnce: true,
        root: null,
        rootMargin: '300px 0px -200px 0px'
    });

    const [videoThree, videoThreeInView] = useInView({
        triggerOnce: true,
        rootMargin: '-200px 0px -200px 0px'
    });

    const [journeyFinish, journeyFinishInView] = useInView({
        triggerOnce: true,
        rootMargin: '-200px 0px -200px 0px',
        threshold: 0.8
    });

    const [sectionHeaderInView, setSectionHeaderInView] = useState(false);

    const trackVisibleSectionOnScroll = sectionId => {
        if (typeof window !== 'undefined') {
            const scrollPosition = window.pageYOffset;

            const element = document.getElementById(sectionId);

            if (element !== null) {
                const elementTop =
                    scrollPosition + element.getBoundingClientRect().top;
                const elementHeight = element.offsetHeight || 0;
                const elementBottom = elementTop + elementHeight;
                const topNavHeight =
                    document.getElementsByClassName('navbar')[0].offsetHeight ||
                    0;

                if (
                    elementTop <= scrollPosition + topNavHeight &&
                    scrollPosition + topNavHeight <= elementBottom
                ) {
                    setSectionHeaderInView(true);
                } else {
                    setSectionHeaderInView(false);
                }
            }
        }
    };

    useEffect(() => {
        const fn = UtilsService.throttle(() => {
            trackVisibleSectionOnScroll('client-journey-sticky-top');
        }, 100);

        window.addEventListener('scroll', fn);

        return () => {
            window.removeEventListener('scroll', fn);
        };
    });

    return (
        <div ref={clientJourneyRef}>
            <FeatureBlockSection customClass="client-journey">
                <FeatureBlockContent>
                    <FeatureBlockText
                        primary={t('ClientJourneyBlock.title')}
                        fullWidth
                    />
                    {/* --- STICKY TOP --- */}

                    <div
                        id="client-journey-sticky-top"
                        className="client-journey-scroll"
                    >
                        <div
                            className={`client-journey-scroll__wrapper client-journey-scroll__wrapper--center client-journey-scroll__fixed-top ${
                                sectionHeaderInView
                                    ? 'client-journey-scroll__fixed-top--fixed'
                                    : ''
                            }`}
                        >
                            <div className="client-journey-scroll__fixed-top__half">
                                <HBMediaElement
                                    name="marketing_site/client-journey/journey-you.png"
                                    alt={t('ClientJourneyBlock.you')}
                                />
                                <p className="client-journey-scroll__fixed-top__text">
                                    {t('ClientJourneyBlock.you')}
                                </p>
                            </div>
                            <div className="client-journey-scroll__fixed-top__half">
                                <HBMediaElement
                                    name="marketing_site/client-journey/journey-your-client.png"
                                    alt={t('ClientJourneyBlock.client')}
                                />
                                <p className="client-journey-scroll__fixed-top__text client-journey-scroll__fixed-top__text--client">
                                    {t('ClientJourneyBlock.client')}
                                </p>
                            </div>
                        </div>
                        {/* Dashed line */}
                        <div className="client-journey__scroll-container">
                            <HBMediaElement
                                customClass="client-journey__scroll-container__journey"
                                name={
                                    breakpoint.largeUp
                                        ? 'marketing_site/client-journey/journey-path-desktop.svg'
                                        : 'marketing_site/client-journey/journey-path-mobile.svg'
                                }
                                lazyLoad={false}
                                forceImgTag
                            />
                            {clientJourneyInView && (
                                <div className="client-journey__reveal"></div>
                            )}
                        </div>
                        {/* --- MAIN COLUMNS --- */}
                        <div className="client-journey-scroll__wrapper client-journey-scroll__wrapper--step-one">
                            <div className="client-journey-scroll__block client-journey-scroll__block--left">
                                {/* --- STEP ONE --- */}

                                <div ref={dotOneRef}>
                                    <HBMediaElement
                                        customClass={`client-journey__invisible client-journey__dot client-journey__dot--one ${
                                            dotOneInView
                                                ? 'client-journey__visible'
                                                : ''
                                        }`}
                                        name="marketing_site/client-journey/journey-dot-desktop-rebrand.svg"
                                        forceImgTag
                                    />
                                </div>

                                <HBMediaElement
                                    customClass={`client-journey__invisible client-journey__dot client-journey__dot--two ${
                                        stepTwoInView
                                            ? 'client-journey__visible'
                                            : ''
                                    }`}
                                    name="marketing_site/client-journey/journey-dot-desktop-rebrand.svg"
                                    forceImgTag
                                />

                                <div
                                    className={`client-journey__invisible client-journey-scroll__text-box ${
                                        dotOneInView
                                            ? 'client-journey__visible'
                                            : ''
                                    }`}
                                >
                                    <p>{t('ClientJourneyBlock.steps.step1')}</p>
                                    <p className="client-journey-scroll__text-box__subscript">
                                        {t(
                                            'ClientJourneyBlock.steps.step1subscript'
                                        )}
                                    </p>
                                </div>
                                <div ref={videoOne}>
                                    <HBMediaElement
                                        customClass={`client-journey__invisible client-journey-scroll__video client-journey-scroll__video--step1 ${
                                            videoOneInView
                                                ? 'client-journey__visible'
                                                : ''
                                        }`}
                                        type="video"
                                        autoPlay={videoOneInView}
                                        muted
                                        playsInline
                                        name="marketing_site/client-journey/journey-member-template-selection"
                                        alt={t(
                                            'ClientJourneyBlock.steps.step1'
                                        )}
                                    />
                                </div>

                                {/* --- STEP TWO --- */}

                                <div
                                    ref={stepTwoRef}
                                    className={`client-journey__invisible client-journey-scroll__text-box ${
                                        stepTwoInView
                                            ? 'client-journey__visible'
                                            : ''
                                    }`}
                                >
                                    <p>{t('ClientJourneyBlock.steps.step2')}</p>
                                </div>
                                <div
                                    ref={videoTwo}
                                    className={`client-journey__invisible ${
                                        videoTwoInView
                                            ? 'client-journey__visible'
                                            : ''
                                    }`}
                                >
                                    <HBMediaElement
                                        customClass="client-journey-scroll__video"
                                        type="video"
                                        autoPlay={videoTwoInView}
                                        loop
                                        muted
                                        playsInline
                                        name="marketing_site/client-journey/journey-member"
                                        alt={t(
                                            'ClientJourneyBlock.steps.step2'
                                        )}
                                    />
                                    <HBMediaElement
                                        customClass="client-journey__dot client-journey__dot--send"
                                        name={
                                            breakpoint.largeUp
                                                ? 'marketing_site/client-journey/journey-airplane-rebrand.svg'
                                                : 'marketing_site/client-journey/journey-airplane-mobile_1-rebrand.svg'
                                        }
                                        forceImgTag
                                    />
                                </div>
                            </div>
                        </div>
                        {/* --- STEP THREE --- */}
                        <div className="client-journey-scroll__wrapper client-journey-scroll__wrapper--center">
                            <div className="client-journey-scroll__block client-journey-scroll__block--features">
                                {/* --- FEATURE BOXES --- */}
                                <div
                                    className={`client-journey-scroll__feature-box client-journey__invisible ${
                                        videoThreeInView
                                            ? 'client-journey__visible'
                                            : ''
                                    }`}
                                >
                                    <HBMediaElement
                                        name={
                                            breakpoint.largeUp
                                                ? 'marketing_site/client-journey/journey-notification-viewed-rebrand.svg'
                                                : 'marketing_site/client-journey/journey-notification-viewed-mobile-rebrand.svg'
                                        }
                                        alt={t(
                                            'ClientJourneyBlock.features.proposals'
                                        )}
                                        forceImgTag
                                    />
                                    {t('ClientJourneyBlock.features.proposals')}
                                </div>
                                <div
                                    className={`client-journey-scroll__feature-box client-journey__invisible ${
                                        videoThreeInView
                                            ? 'client-journey__visible'
                                            : ''
                                    }`}
                                >
                                    <HBMediaElement
                                        name={
                                            breakpoint.largeUp
                                                ? 'marketing_site/client-journey/journey-notification-service-rebrand.svg'
                                                : 'marketing_site/client-journey/journey-notification-service-mobile-rebrand.svg'
                                        }
                                        alt={t(
                                            'ClientJourneyBlock.features.services'
                                        )}
                                        forceImgTag
                                    />
                                    {t('ClientJourneyBlock.features.services')}
                                </div>
                                <div
                                    className={`client-journey-scroll__feature-box client-journey__invisible ${
                                        videoThreeInView
                                            ? 'client-journey__visible'
                                            : ''
                                    }`}
                                >
                                    <HBMediaElement
                                        name={
                                            breakpoint.largeUp
                                                ? 'marketing_site/client-journey/journey-notification-signed-rebrand.svg'
                                                : 'marketing_site/client-journey/journey-notification-signed-mobile-rebrand.svg'
                                        }
                                        alt={t(
                                            'ClientJourneyBlock.features.contracts'
                                        )}
                                        forceImgTag
                                    />
                                    {t('ClientJourneyBlock.features.contracts')}
                                </div>
                                <div
                                    className={`client-journey-scroll__feature-box client-journey__invisible ${
                                        videoThreeInView
                                            ? 'client-journey__visible'
                                            : ''
                                    }`}
                                >
                                    <HBMediaElement
                                        name={
                                            breakpoint.largeUp
                                                ? 'marketing_site/client-journey/journey-notification-paid-rebrand.svg'
                                                : 'marketing_site/client-journey/journey-notification-paid-mobile-rebrand.svg'
                                        }
                                        alt={t(
                                            'ClientJourneyBlock.features.payments'
                                        )}
                                        forceImgTag
                                    />
                                    {t('ClientJourneyBlock.features.payments')}
                                </div>
                            </div>
                            <div className="client-journey-scroll__block client-journey-scroll__block--mobile">
                                <HBMediaElement
                                    customClass={`client-journey__invisible client-journey__dot client-journey__dot--three ${
                                        stepThreeInView
                                            ? 'client-journey__visible'
                                            : ''
                                    }`}
                                    name="marketing_site/client-journey/journey-dot-desktop-rebrand.svg"
                                    forceImgTag
                                />
                                <HBMediaElement
                                    customClass={`client-journey__invisible client-journey__dot client-journey__dot--mail ${
                                        stepThreeInView
                                            ? 'client-journey__visible'
                                            : ''
                                    }`}
                                    name="marketing_site/client-journey/journey-email.svg"
                                    forceImgTag
                                />
                                <HBMediaElement
                                    customClass={`client-journey__invisible client-journey__dot client-journey__dot--link ${
                                        stepThreeInView
                                            ? 'client-journey__visible'
                                            : ''
                                    }`}
                                    name="marketing_site/client-journey/journey-link.svg"
                                    forceImgTag
                                />
                                <div
                                    ref={stepThreeRef}
                                    className={`client-journey__invisible client-journey-scroll__text-box ${
                                        stepThreeInView
                                            ? 'client-journey__visible'
                                            : ''
                                    }`}
                                >
                                    <p>{t('ClientJourneyBlock.steps.step3')}</p>
                                    <p className="client-journey-scroll__text-box__subscript">
                                        {t(
                                            'ClientJourneyBlock.steps.step3subscript'
                                        )}
                                    </p>
                                </div>
                                <div
                                    ref={videoThree}
                                    className={`client-journey__invisible  ${
                                        videoThreeInView
                                            ? 'client-journey__visible'
                                            : ''
                                    }`}
                                >
                                    <HBMediaElement
                                        customClass="client-journey-scroll__mobile-video"
                                        type="video"
                                        autoPlay={videoTwoInView}
                                        muted
                                        playsInline
                                        name="marketing_site/client-journey/journey-client2"
                                        alt={t(
                                            'ClientJourneyBlock.steps.step3'
                                        )}
                                    />
                                </div>
                                <div className="client-journey-scroll__text-box-reminder">
                                    <HBMediaElement
                                        name="marketing_site/client-journey/journey-notification-reminder-mobile.svg"
                                        forceImgTag
                                    />
                                    {t('ClientJourneyBlock.reminder')}
                                </div>
                            </div>
                        </div>
                        <div
                            ref={journeyFinish}
                            className={`client-journey__invisible client-journey__dot client-journey__dot--finish ${
                                journeyFinishInView
                                    ? 'client-journey__visible'
                                    : ''
                            }`}
                        >
                            <HBMediaElement
                                name="marketing_site/client-journey/journey-end-rebrand.svg"
                                forceImgTag
                            />
                        </div>
                    </div>
                </FeatureBlockContent>
            </FeatureBlockSection>
        </div>
    );
};

export default ClientJourneyBlock;
