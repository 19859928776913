const TemplateBlockStore = {
    contract: {
        hasGatedDownload: true,
        ctaText: 'TemplateElements.common.ctaCustomize',
        templateBlockCta: {
            ctaBoxTitle: 'TemplateGallery.contract.ctaBoxTitle',
            ctaBoxImage: 'marketing_site/lp/agreement/desktop/Group_24.svg'
        }
    },
    invoice: {
        hasGatedDownload: true,
        ctaText: 'TemplateElements.common.ctaLoadToHb',
        templateBlockCta: {
            ctaBoxTitle: 'TemplateGallery.invoice.ctaBoxTitle',
            ctaBoxImage: 'marketing_site/invoice-generator-lp/clipboard.png'
        }
    }
};

export default TemplateBlockStore;
