import { useTranslation, Trans } from 'react-i18next';
import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import FeatureBlockText from '../../FeatureBlockElements/FeatureBlockText/FeatureBlockText';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';

import './templates-vertical-grid-rebrand.scss';

const gridData = [
    {
        id: 'florist',
        imagePath: 'marketing_site/smart-files/industries-florists-rebrand.png'
    },
    {
        id: 'photographers',
        imagePath:
            'marketing_site/smart-files/industries-photographers-rebrand.png'
    },
    {
        id: 'webDesigners',
        imagePath:
            'marketing_site/smart-files/industries-web-designers-rebrand.png'
    },
    {
        id: 'eventPlanners',
        imagePath:
            'marketing_site/smart-files/industries-event-planners-rebrand.png'
    },
    {
        id: 'marketingConsultants',
        imagePath:
            'marketing_site/smart-files/industries-marketing-consultants-rebrand.png'
    },
    {
        id: 'videographers',
        imagePath:
            'marketing_site/smart-files/industries-videographers-rebrand.png'
    },
    {
        id: 'venues',
        imagePath: 'marketing_site/smart-files/industries-venues-rebrand.png'
    },
    {
        id: 'caterers',
        imagePath: 'marketing_site/smart-files/industries-caterers-rebrand.png'
    },
    {
        id: 'coaches',
        imagePath: 'marketing_site/smart-files/industries-coaches-rebrand.png'
    },
    {
        id: 'businessConsultants',
        imagePath:
            'marketing_site/smart-files/industries-business-consultants-rebrand.png'
    }
];

const TemplatesVerticalGrid = () => {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection customClass="templates-vertical-grid">
            <FeatureBlockContent>
                <FeatureBlockText
                    primary={t('TemplatesVerticalGrid.title')}
                    secondary={t('TemplatesVerticalGrid.subtitle')}
                    fullWidth
                />
                <div className="templates-vertical-grid__grid">
                    {gridData.map(({ id, imagePath }) => (
                        <div
                            key={id}
                            className="templates-vertical-grid__grid-item"
                        >
                            <HBMediaElement name={imagePath} />
                            <p className="templates-vertical-grid__grid-item-description">
                                {t(`TemplatesVerticalGrid.grid.${id}`)}
                            </p>
                        </div>
                    ))}
                </div>
                <p className="templates-vertical-grid__post-title">
                    <Trans>{t('TemplatesVerticalGrid.postTitle')}</Trans>
                </p>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default TemplatesVerticalGrid;
