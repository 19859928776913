import { useTranslation, Trans } from 'react-i18next';
import { TRANSLATION_COMPONENTS } from '../../../constants/constants';
import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import FeatureBlockText from '../../FeatureBlockElements/FeatureBlockText/FeatureBlockText';
import FeatureBlockUserQuote from '../../FeatureBlockElements/FeatureBlockUserQuote/FeatureBlockUserQuote';
import FeatureBlockImage from '../../FeatureBlockElements/FeatureBlockImage/FeatureBlockImage';
import AgreementSignBlockStore from './AgreementSignBlockStore';
import ProposalsToast from '../../ProposalsToast/ProposalsToast';

import './agreement-sign-block.scss';

const AgreementSignBlock = () => {
    const { t } = useTranslation();

    const { featureImage, quoteAvatar } = AgreementSignBlockStore;
    return (
        <FeatureBlockSection customClass="feature-block__agreement-sign">
            <FeatureBlockContent>
                <FeatureBlockImage
                    customClass="feature-block__agreement-sign-img"
                    featureImage={featureImage}
                    width={570}
                    crop="scale"
                />
                <ProposalsToast
                    position="bottom"
                    iconClass="icon-hb-nx-signature"
                    content={t('featureBlock.agreementSign.toastContent')}
                    timeAgo={t('featureBlock.agreementSign.timeAgo')}
                    entranceDirection="right"
                />
            </FeatureBlockContent>
            <FeatureBlockContent position="right">
                <FeatureBlockText
                    primary={
                        <Trans
                            i18nKey="featureBlock.agreementSign.title"
                            components={TRANSLATION_COMPONENTS}
                        />
                    }
                    secondary={t('featureBlock.agreementSign.indicator.file')}
                    subheading={t('featureBlock.agreementSign.subtitle')}
                />
                <FeatureBlockUserQuote
                    hideQuotes
                    name={t('featureBlock.agreementSign.quote.userName')}
                    jobTitle={t('featureBlock.agreementSign.quote.jobTitle')}
                    avatar={quoteAvatar}
                    avatarWidth={40}
                    avatarCrop="thumb"
                    avatarGravity="face"
                    quote={t('featureBlock.agreementSign.quote.text')}
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default AgreementSignBlock;
