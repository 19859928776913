import { useTranslation } from 'react-i18next';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';
import HBText from '../../honeybook-ui/HBText/HBText';

import './need-invoice-templates-block.scss';

const textTransIds = ['create', 'paid', 'brand'];

const NeedInvoiceTemplatesBlock = () => {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection customClass="need-invoice-templates-block">
            <FeatureBlockContent>
                <HBText
                    customClass="need-invoice-templates-block__title"
                    tag="h2"
                    type="title-m"
                >
                    {t('InvoiceHub.needInvoiceTemplates.title')}
                </HBText>
                <div className="need-invoice-templates-block__text-container">
                    {textTransIds.map(transId => (
                        <div
                            className="need-invoice-templates-block__text"
                            key={transId}
                        >
                            <HBText
                                tag="p"
                                type="subtitle-l"
                                customClass="need-invoice-templates-block__subtitle"
                            >
                                {t(
                                    `InvoiceHub.needInvoiceTemplates.${transId}.title`
                                )}
                            </HBText>
                            <HBText
                                tag="p"
                                type="body-s"
                                customClass="need-invoice-templates-block__description"
                            >
                                {t(
                                    `InvoiceHub.needInvoiceTemplates.${transId}.description`
                                )}
                            </HBText>
                        </div>
                    ))}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default NeedInvoiceTemplatesBlock;
