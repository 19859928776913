import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import '../styles/404.scss';
import NavigateLink from '../components/Link/NavigateLink';

const NotFound = () => {
    const { t } = useTranslation();

    const ogTags = {
        title: 'Page not found',
        description: 'Error 404: Page not found',
        noindex: true
    };
    return (
        <Layout
            customClass="not-found"
            ogTags={ogTags}
            slimNav
            noFooter
            noPromo
        >
            <div className="not-found__container">
                <div className="not-found__content">
                    <HBMediaElement
                        customClass="not-found__sorry"
                        name="marketing_site/404/Sorry_Lockup__as_one.svg"
                        alt=""
                        lazyLoad={false}
                        forceImgTag
                    />
                    <HBMediaElement
                        customClass="not-found__sorry--mweb"
                        name="marketing_site/404/Sorry_Lockup.svg"
                        alt=""
                        forceImgTag
                        lazyLoad={false}
                    />
                    <HBMediaElement
                        customClass="not-found__monitor"
                        name="marketing_site/404/Error.gif"
                        alt=""
                        lazyLoad={false}
                    />
                    <p className="not-found__title">{t('NotFound.title')}</p>
                    <p className="not-found__subtitle">
                        {t('NotFound.subtitle')}
                    </p>
                    <NavigateLink
                        source="404_to_home"
                        link="/"
                        className="not-found__link hbui-button hbui-button--large hbui-button--primary--dark"
                    >
                        {t('NotFound.buttonText')}
                    </NavigateLink>
                    <HBMediaElement
                        customClass="not-found__arrow"
                        name="marketing_site/404/Arrow_Desktop.svg"
                        alt=""
                        forceImgTag
                        lazyLoad={false}
                    />
                </div>
            </div>
        </Layout>
    );
};

export default NotFound;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "404"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
