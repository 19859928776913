import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { withTranslation } from 'react-i18next';
import { ButtonStyles } from '@honeybook-ui/HBButton/HBButton';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../../FeatureBlockElements';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';

import './one-business-platform-block.scss';

const OneBusinessPlatformBlock = ({ t, block, id, customClass, index }) => {
    const [ref, inView] = useInView({
        threshold: 0.7,
        triggerOnce: true
    });

    const activeClass = inView ? 'one-business-platform-block--active' : '';

    const isEven = index % 2 === 1;

    return (
        <div ref={ref}>
            <FeatureBlockSection
                customClass={`${customClass} ${activeClass}`}
                {...(isEven ? { lightBackground: true } : null)}
            >
                <FeatureBlockContent
                    position={isEven ? 'right' : 'left'}
                    customClass="one-business-platform-block__container"
                >
                    <div className="one-business-platform-block__wrapper">
                        <FeatureBlockText
                            subheading={t(
                                `oneBusinessPlatform.blockContent.${id}.title`
                            )}
                            bodyText={t(
                                `oneBusinessPlatform.blockContent.${id}.body`
                            )}
                        />
                        <RegistrationForm
                            source={`contracts block ${block.id}`}
                            size="small"
                            customClass="one-business-platform-block__btn--desk"
                            buttonText={t(
                                `oneBusinessPlatform.blockContent.${id}.ctaCopy`
                            )}
                            buttonStyle={ButtonStyles.PRIMARY_DARK}
                        />
                    </div>
                </FeatureBlockContent>
                <FeatureBlockContent
                    position={isEven ? 'left' : 'right'}
                    customClass="one-business-platform-block__container-media"
                >
                    <div className="one-business-platform-block__wrapper">
                        {block.images &&
                            block.images.map(
                                ({
                                    customClass,
                                    publicId,
                                    width,
                                    altTransId
                                }) => {
                                    return (
                                        <HBMediaElement
                                            customClass={customClass}
                                            key={publicId}
                                            name={publicId}
                                            width={width}
                                            alt={
                                                altTransId ? t(altTransId) : ''
                                            }
                                            lazyLoad={false}
                                        />
                                    );
                                }
                            )}
                    </div>
                    <RegistrationForm
                        source={`contracts block ${block.id}`}
                        size="small"
                        customClass="one-business-platform-block__btn--mobile"
                        buttonText={t(
                            `oneBusinessPlatform.blockContent.${id}.ctaCopy`
                        )}
                        buttonStyle={ButtonStyles.PRIMARY_DARK}
                    />
                </FeatureBlockContent>
            </FeatureBlockSection>
        </div>
    );
};

OneBusinessPlatformBlock.propTypes = {
    t: PropTypes.func,
    id: PropTypes.string,
    customClass: PropTypes.string,
    index: PropTypes.number
};

export default withTranslation()(OneBusinessPlatformBlock);
