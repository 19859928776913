class CookiesService {
    /**
     * Helper function for setting client-side cookies
     * @param cookieName Cookie name to set
     * @param cookieValue Cookie value to set
     * @param expiration Expiration in days
     */

    setCookie = (
        cookieName: string,
        cookieValue: string,
        expiration: number
    ): void => {
        const d = new Date();

        d.setTime(d.getTime() + expiration * 24 * 60 * 60 * 1000);
        const expires = `expires= ${d.toUTCString()}`;
        document.cookie = `${cookieName}=${encodeURIComponent(
            cookieValue
        )};${expires};path=/`;
    };

    /**
     * Helper function for reading a client-side cookie's value
     * @param cookieName Cookie name to read
     * @returns {String} Cookie's value that was found. If the cookie is not found than undefined is returned.
     */
    getCookie = (cookieName: string): string => {
        const name = (cookieName || '').trim();
        const cookies = document.cookie.split('; ');
        let cookie = '';

        for (let i = 0; i < cookies.length; i++) {
            const currCookie = cookies[i];
            const split = currCookie.split('=');

            if ((split[0] || '').trim() === name) {
                cookie = decodeURIComponent(split[1]);
            }
        }
        return cookie;
    };

    /**
     * Helper function for deleting a client-side
     * @param cookieName
     */
    deleteCookie = (cookieName: string): void => {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    };
}

export default new CookiesService();
