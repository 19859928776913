import { memo, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import Hero from '../Hero/Hero';
import HeroStrip from '../HeroStrip/HeroStrip';
import HeroColumn from '../HeroColumn/HeroColumn';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import VideoModal from '../../Modals/VideoModal/VideoModal';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';

import './templates-hero.scss';

const TemplatesHero = () => {
    const { t } = useTranslation();

    const [videoModalOpen, setVideoModalOpen] = useState(false);

    const toggleVideoModal = () => {
        AnalyticsService.trackClick(AnalyticsEvents.value_props_hero_video, {
            source: 'toggle hero video modal'
        });
        setVideoModalOpen(videoModalOpen => !videoModalOpen);
    };

    return (
        <Hero customClass="templates-hero">
            <HeroStrip>
                <HeroColumn
                    columnWidth={3}
                    customClass="templates-hero__registration"
                >
                    <h1 className="templates-hero__title">
                        {t(`TemplatesHero.title`)}
                    </h1>
                    <h2 className="templates-hero__subtitle">
                        <Trans>{t(`TemplatesHero.subtitle`)}</Trans>
                    </h2>
                    <RegistrationForm
                        source="perf marketing hero"
                        size="large"
                        showNoCcRequired
                    />
                </HeroColumn>
                <HeroColumn
                    columnWidth={5}
                    customClass="templates-hero__media-block"
                >
                    <HBMediaElement
                        type="video"
                        autoPlay
                        muted
                        loop
                        playsInline
                        customClass="templates-hero__img"
                        name="marketing_site/smart-files/templates-carousel-desktop-no-bg"
                        alt={t(`TemplatesHero.title`)}
                        lazyLoad={false}
                    />
                </HeroColumn>
            </HeroStrip>
            <VideoModal
                video={{
                    id: 'hero',
                    videoURL:
                        'https://www.youtube.com/embed/ff0FafxdJWA?autoplay=1&controls=1'
                }}
                customClass="templates-hero__video-modal"
                isOpen={videoModalOpen}
                onClose={toggleVideoModal}
            />
        </Hero>
    );
};

export default memo(TemplatesHero);
