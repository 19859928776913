import { memo, useCallback, useState, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import HBIcon from '../honeybook-ui/HBIcon/HBIcon';

import './filter-checklist.scss';

import type { ChangeEvent } from 'react';

interface Props {
    list: string[];
    transKey: string;
    title?: string;
    onSelect: (selectedItem: string) => void;
    onClearAll: () => void;
}

const initArray: string[] = [];

function FilterChecklist({
    title,
    list,
    transKey,
    onSelect,
    onClearAll
}: Props): JSX.Element {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();
    const [selectedItems, setSelectedItems] = useState<string[]>(initArray);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleClearAll = useCallback(() => {
        setSelectedItems([]);
        onClearAll();
    }, [onClearAll]);

    const handleToggleDropdown = useCallback(() => {
        setDropdownOpen(!dropdownOpen);
    }, [dropdownOpen]);

    const hasItemsSelected = selectedItems.length > 0;

    const dropdownTitle = useMemo(
        () =>
            hasItemsSelected ? (
                <>
                    {selectedItems.map((selectedItem, i) => {
                        const wordDivider = i > 0 ? ', ' : '';

                        return `${wordDivider}${t(
                            `${transKey}.${selectedItem}`
                        )}`;
                    })}
                </>
            ) : (
                title
            ),
        [hasItemsSelected, selectedItems, t, title, transKey]
    );

    const renderCloseButton = useCallback(() => {
        return selectedItems.length > 0 ? (
            <button
                type="button"
                className="filter-checklist__clear"
                onClick={handleClearAll}
            >
                Clear all
            </button>
        ) : null;
    }, [handleClearAll, selectedItems.length]);

    const renderArrows = useCallback(() => {
        return (
            <button
                type="button"
                className="filter-checklist__dropdown"
                onClick={handleToggleDropdown}
            >
                <HBIcon name="nx-settings" />
                {dropdownTitle}
                {dropdownOpen ? (
                    <HBIcon name="nx-arrow-up-16" />
                ) : (
                    <HBIcon name="nx-arrow-down-16" />
                )}
            </button>
        );
    }, [dropdownOpen, dropdownTitle, handleToggleDropdown]);

    const onChange = useCallback(
        ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
            onSelect(value);

            if (!selectedItems.includes(value)) {
                setSelectedItems(arr => [...arr, value]);
            } else if (selectedItems.length) {
                setSelectedItems(selectedItems.filter(i => i !== value));
            }
        },
        [onSelect, selectedItems]
    );

    return (
        <div className="filter-checklist">
            {title && <h2 className="filter-checklist__title">{title}</h2>}

            {/* "Clear all" button - medium screens and up */}
            {breakpoints.mediumUp && renderCloseButton()}

            {/* Filter select dropdown - mweb only */}
            {breakpoints.medium && renderArrows()}

            <div className="filter-checklist__item-container">
                {((breakpoints.medium && dropdownOpen) ||
                    !breakpoints.medium) &&
                    list.map(listItem => {
                        const isChecked = selectedItems.includes(listItem);

                        return (
                            <div
                                className="filter-checklist__item"
                                key={listItem}
                            >
                                <input
                                    className="filter-checklist__input"
                                    onChange={onChange}
                                    type="checkbox"
                                    id={listItem}
                                    name={listItem}
                                    value={listItem}
                                    checked={isChecked}
                                />
                                <label
                                    className="filter-checklist__label"
                                    htmlFor={listItem}
                                >
                                    <span className="filter-checklist__checkbox" />
                                    {t(`${transKey}.${listItem}`)}
                                </label>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}

FilterChecklist.propTypes = {
    list: PropTypes.arrayOf(PropTypes.string).isRequired,
    transKey: PropTypes.string.isRequired,
    title: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    onClearAll: PropTypes.func.isRequired
};

export default memo(FilterChecklist);
