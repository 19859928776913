import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';

import TemplatesHero from '../components/Hero/TemplatesHero/TemplatesHero';
import TemplateTabsBlock from '../components/FeatureBlocks/TemplateTabsBlock/TemplateTabsBlock';
import InteractiveFilesBlock from '../components/FeatureBlocks/InteractiveFilesBlock/InteractiveFilesBlock';
import ClientJourneyBlock from '../components/FeatureBlocks/ClientJourneyBlock/ClientJourneyBlock';
import BestPracticesBlock from '../components/FeatureBlocks/BestPracticesBlock/BestPracticesBlock';
import TemplatesVerticalGridRebrand from '../components/FeatureBlocks/TemplatesVerticalGrid/TemplatesVerticalGridRebrand';
import FeatureValuePropsBlock from '../components/FeatureBlocks/FeatureValuePropsBlock/FeatureValuePropsBlock';
import CaseStudyBlockRebrand from '../components/FeatureBlocks/CaseStudyBlock/CaseStudyBlockRebrand';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';
import DiscoverLinkBlock from '../components/FeatureBlocks/DiscoverLinkBlock/DiscoverLinkBlock';
import PromoBlockLeftRebrand from '../components/FeatureBlocks/PromoBlockLeft/PromoBlockLeft';

import '../styles/templates.scss';

const ogTags = {
    url: 'https://www.honeybook.com/templates',
    title: 'Clientflow Templates: Invoices, Contracts, Proposals',
    description: `Customizable templates built to help independent business owners sell services and book clients faster. Get started for Free.`
};

const faqs = [
    {
        id: 'whatIs'
    },
    {
        id: 'HBCostPricing'
    },
    {
        id: 'freeTrialWork'
    },
    {
        id: 'discounts'
    },
    {
        id: 'changePlan'
    },
    {
        id: 'cancelPlan'
    },
    {
        id: 'paymentProcessingFees'
    },
    {
        id: 'clientSetup'
    }
];

const TemplatesPage = () => {
    const { t } = useTranslation();

    return (
        <div className="templates">
            <Layout ogTags={ogTags}>
                <TemplatesHero />
                <TemplateTabsBlock />
                <ClientJourneyBlock />
                <InteractiveFilesBlock />
                <BestPracticesBlock />
                <TemplatesVerticalGridRebrand />
                <FeatureValuePropsBlock title={t('featureValueProps.title')} />
                <CaseStudyBlockRebrand />
                <FAQBlock title={t('TemplatesFAQs.title')} faqs={faqs} />
                <DiscoverLinkBlock />
                <PromoBlockLeftRebrand
                    title={t('PromoBlockLeftYellow.title')}
                    customClass="promo-block-left--yellow"
                    source="promo block left - yellow"
                    buttonStyle="primary--dark"
                />
            </Layout>
        </div>
    );
};

export default TemplatesPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: {
                    in: [
                        "templatesRebrand"
                        "common"
                        "templates"
                        "faqs"
                        "getStarted"
                    ]
                }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
