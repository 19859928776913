import { memo, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import FilterChecklist from '../../FilterChecklist/FilterChecklist';
import TemplateBlock from '../TemplateBlock/TemplateBlock';
import UtilsService from '../../../Services/UtilsService';

import TemplateStore from '../../../stores/TemplatesStore';

import './template-gallery.scss';

const { templates, templateLinkPath } = TemplateStore;

interface Props {
    templateTypeId?: string;
}

function TemplateGallery({ templateTypeId = 'invoice' }: Props): JSX.Element {
    const { t } = useTranslation();

    const allVerticals = useMemo(() => {
        return (
            templates
                // Filter for only contract templates
                .filter(template => template.type === templateTypeId)
                // Convert each template to its vertical string
                .map(template => template.vertical)
                // Remove duplicates verticals
                .reduce((accumulator, vertical) => {
                    if (!accumulator.includes(vertical))
                        accumulator.push(vertical);

                    return accumulator;
                }, [])
                .sort()
        );
    }, [templateTypeId]);

    const [selectedVerticals, setSelectedVerticals] = useState<string[]>([]);

    const handleListSelect = useCallback(
        (selectedItem: string) => {
            if (selectedVerticals.includes(selectedItem)) {
                setSelectedVerticals(
                    selectedVerticals.filter(v => v !== selectedItem)
                );
            } else {
                setSelectedVerticals([...selectedVerticals, selectedItem]);

                window.setTimeout(() => {
                    UtilsService.scrollToElementId(`${selectedItem}-block`, 50);
                }, 100);
            }
        },
        [selectedVerticals]
    );

    const handleClearAll = useCallback(() => {
        setSelectedVerticals([]);
    }, []);

    const verticals = useMemo(
        () =>
            (selectedVerticals.length
                ? selectedVerticals
                : allVerticals
            ).sort(),
        [allVerticals, selectedVerticals]
    );

    return (
        <div className="template-gallery">
            <div className="template-gallery__sidebar">
                <FilterChecklist
                    title={t('TemplateGallery.filterChecklist.title')}
                    list={allVerticals}
                    transKey="TemplateGallery.verticals"
                    onSelect={handleListSelect}
                    onClearAll={handleClearAll}
                />
            </div>

            <div className="template-gallery__template-blocks">
                {verticals.sort().map(vertical => {
                    const blockTemplates = templates.filter(
                        template => template.vertical === vertical
                    );
                    return (
                        <div id={`${vertical}-block`} key={vertical}>
                            <TemplateBlock
                                key={vertical}
                                templateTypeId={templateTypeId}
                                vertical={vertical}
                                templates={blockTemplates}
                                verticalLinks={templateLinkPath[vertical]}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

TemplateGallery.propTypes = {
    templateTypeId: PropTypes.string
};

export default memo(TemplateGallery);
