import { memo } from 'react';
import classnames from 'classnames';

export type TabId = string | number;

export interface TabProps {
    id: string;
    className: string;
    /**
     * Panel content, rendered by the parent `Tabs` when this tab is active.
     * If omitted, no panel will be rendered for this tab.
     */
    panel: JSX.Element;
    title: string;
}

function Tab({ className, panel }: TabProps): JSX.Element {
    return (
        <div className={classnames(`bp3-tab-panel`, className)} role="tablist">
            {panel}
        </div>
    );
}

export default memo(Tab);
